import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

import * as styles from  './404.module.scss'
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout headerCheck={false} page="404">
    <Seo
      title="ご指定のページは見つかりません | becoz (ビコーズ)"
      description="DATAFLUCT (データフラクト) が運営する becoz (ビコーズ) のブランドサイト。becoz はデータサイエンスの力で環境価値を見える化し、カーボンニュートラルに向けた行動を促進する環境価値流通プラットフォームです。"
    />

    <section className={`section ${styles.sectionNotFound}`}>
      <div className={styles.background}>
        <StaticImage src="../images/404/kv_gradient.pc.jpg" alt="" className="pc-only"/>
        <StaticImage src="../images/404/kv_gradient.sp.jpg" alt="" className="sp-only"/>

        <div className={styles.notFoundSymbols}>
          <span>4</span>
          <span>0</span>
          <span>4</span>
        </div>
      </div>

      <div className={styles.notFoundContent}>
        <p> Page not found.</p>
        <Link href="/" className={styles.buttonTop}>TOPへ戻る</Link>
      </div>
    </section>
    
  </Layout>
)

export default NotFoundPage
